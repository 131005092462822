/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCharmsRoom = /* GraphQL */ `
  mutation CreateCharmsRoom(
    $input: CreateCharmsRoomInput!
    $condition: ModelCharmsRoomConditionInput
  ) {
    createCharmsRoom(input: $input, condition: $condition) {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCharmsRoom = /* GraphQL */ `
  mutation UpdateCharmsRoom(
    $input: UpdateCharmsRoomInput!
    $condition: ModelCharmsRoomConditionInput
  ) {
    updateCharmsRoom(input: $input, condition: $condition) {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCharmsRoom = /* GraphQL */ `
  mutation DeleteCharmsRoom(
    $input: DeleteCharmsRoomInput!
    $condition: ModelCharmsRoomConditionInput
  ) {
    deleteCharmsRoom(input: $input, condition: $condition) {
      id
      question
      ttl
      charmsResults {
        items {
          id
          roomId
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCharmsResults = /* GraphQL */ `
  mutation CreateCharmsResults(
    $input: CreateCharmsResultsInput!
    $condition: ModelCharmsResultsConditionInput
  ) {
    createCharmsResults(input: $input, condition: $condition) {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCharmsResults = /* GraphQL */ `
  mutation UpdateCharmsResults(
    $input: UpdateCharmsResultsInput!
    $condition: ModelCharmsResultsConditionInput
  ) {
    updateCharmsResults(input: $input, condition: $condition) {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCharmsResults = /* GraphQL */ `
  mutation DeleteCharmsResults(
    $input: DeleteCharmsResultsInput!
    $condition: ModelCharmsResultsConditionInput
  ) {
    deleteCharmsResults(input: $input, condition: $condition) {
      id
      roomId
      ttl
      results {
        id
        x
        y
        rotation
        isDragging
        tokens
      }
      charmsRoom {
        id
        question
        ttl
        charmsResults {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
